import AWS from "aws-sdk";
import { AwsClient } from "aws4fetch";
import DateFnsUtils from "@date-io/date-fns";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useEffect, useState } from "react";

import { Backend } from "./backend";
import { MockBackend } from "./mockBackend";
import { Util } from "./util";
import "./App.css";
import EntryEditor from "./EntryEditor";
import EntryViewer from "./EntryViewer";
import { CircularProgress } from "@material-ui/core";
import DateShortcuts from "./DateShortcuts";

export default function App() {
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";
  const reactAppIdentityPoolId = process.env.REACT_APP_IDENTITY_POOL_ID || "";
  const [backend, setBackend] = useState<Backend | MockBackend | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [viewBody, setViewBody] = useState<string>("");
  const [viewSubmittedAt, setViewSubmittedAt] = useState<Date>(new Date());
  const [viewIsRead, setViewIsRead] = useState<boolean>(false);
  const [viewLastReadAt, setViewLastReadAt] = useState<Date | undefined>(
    undefined
  );
  const [viewReadAts, setViewReadAts] = useState<Date[]>([]);
  const [viewMoment, setViewMoment] = useState<string>("");
  const [viewDate, setViewDate] = useState<Date>(new Date());

  useEffect(() => {
    (async function viewDateChanged() {
      console.log("viewDateChanged()");
      setViewBody("");
      setViewSubmittedAt(new Date());
      setViewIsRead(false);
      setViewLastReadAt(undefined);
      setViewReadAts([]);
      setViewMoment("");
      setIsLoading(true);

      if (!backend) {
        console.log("Backend not ready yet.");
        return;
      }

      console.log("viewDate", viewDate);
      const entryId = Util.dateToString(viewDate);
      console.log("entryId", entryId);
      const entry = await backend.getEntry(entryId);
      console.log("entry", entry);
      if (entry) {
        setViewBody(entry.body);
        setViewSubmittedAt(
          entry.submittedAt && Util.stringToDate(entry.submittedAt)
        );
        setViewIsRead(entry.isRead);
        setViewLastReadAt(entry.lastReadAt);
        setViewReadAts(
          entry.readAts?.map((readAtStr: string) => new Date(readAtStr))
        );
        setViewMoment(entry.moment);
      }
      setIsLoading(false);
    })();
  }, [backend, viewDate]);

  async function responseGoogle(response: any) {
    if (!response.credential) {
      throw Error("Google login response missing credential.");
    }

    setIsLoggedIn(true);

    // Add the Google access token to the Amazon Cognito credentials login map.
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: reactAppIdentityPoolId,
      Logins: {
        "accounts.google.com": response.credential,
      },
    });
    AWS.config.region = "us-west-2";

    console.log("Getting AWS credentials.");

    // Obtain AWS credentials
    AWS.config.getCredentials(async function (err: any) {
      // Access AWS resources here.
      console.log("AWS get credentials callback invoked.");
      if (err) {
        console.log("Error response getting AWS credentials.", err);
        console.error(err);
      }

      setBackend(
        new Backend(
          new AwsClient({
            secretAccessKey: AWS?.config?.credentials?.secretAccessKey || "",
            accessKeyId: AWS?.config?.credentials?.accessKeyId || "",
            sessionToken: AWS?.config?.credentials?.sessionToken || "",
            service: "execute-api",
            region: "us-west-2",
          })
        )
      );
    });
  }

  async function handleMarkAsRead() {
    console.log("Handle mark as read.");

    if (!backend) {
      console.log("Backend not ready yet.");
      return;
    }

    const entryId = Util.dateToString(viewDate);
    const now = new Date();

    // Optimistic
    setViewIsRead(true);
    setViewLastReadAt(now);
    setViewReadAts([...viewReadAts, now]);

    // TODO: Smaller loading indicator for mark as read.
    //setIsLoading(true);
    if (await backend.postEntryRead(entryId, now.toISOString())) {
      console.log("Success");
    } else {
      console.log("Failure");
    }
    //setIsLoading(false);
  }

  async function handleSubmit() {
    console.log("Handle submit.");

    if (!backend) {
      console.log("Backend not ready yet.");
      return;
    }

    const entry = {
      entryId: Util.dateToString(viewDate),
      body: viewBody,
      moment: viewMoment,
      submittedAt: new Date().toISOString(),
    };

    setIsLoading(true);
    if (await backend.postEntry(entry)) {
      console.log("Success");
    } else {
      console.log("Failure");
    }
    setIsLoading(false);
  }

  let loginButton: JSX.Element | null = null;
  if (Util.isLocalhost()) {
    if (!backend) {
      setBackend(new MockBackend());
    }
  } else {
    loginButton = (
      <GoogleLogin
        auto_select={true}
        useOneTap={true}
        onSuccess={(response) => responseGoogle(response)}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    );
  }

  const dateShortcuts = (
    <DateShortcuts onChange={(value: Date) => setViewDate(value)} />
  );

  const datePicker = (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        autoOk={true}
        disableFuture={true}
        format="yyyy-MM-dd"
        fullWidth={true}
        inputVariant="outlined"
        margin="normal"
        onChange={(value) => setViewDate(value || new Date())}
        value={viewDate}
      />
    </MuiPickersUtilsProvider>
  );

  const entry = Util.isEditableDate(viewDate) ? (
    <EntryEditor
      body={viewBody}
      moment={viewMoment}
      onBodyChange={(value: string) => setViewBody(value)}
      onMomentChange={(value: string) => setViewMoment(value)}
      onSubmit={handleSubmit}
    />
  ) : (
    <EntryViewer
      body={viewBody}
      submittedAt={viewSubmittedAt}
      isRead={viewIsRead}
      lastReadAt={viewLastReadAt}
      readAts={viewReadAts}
      moment={viewMoment}
      onMarkRead={() => handleMarkAsRead()}
    />
  );

  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <div className="App">
        {isLoading && isLoggedIn ? (
          <div className="distractor">
            <CircularProgress size={24} />
          </div>
        ) : null}
        {isLoggedIn ? null : loginButton}
        {dateShortcuts}
        {backend ? datePicker : null}
        {backend && !isLoading ? entry : null}
      </div>
    </GoogleOAuthProvider>
  );
}
