import { Button } from "@material-ui/core";
import { Util } from "./util";

type Props = {
  onChange: (value: Date) => void;
};

export default function DateShortcuts(props: Props) {
  return (
    <div>
      <Button
        color="default"
        fullWidth={false}
        onClick={(event) => props.onChange(Util.daysAgo(1813))}
        variant="contained"
      >
        -5 yrs
      </Button>

      <Button
        color="default"
        fullWidth={false}
        onClick={(event) => props.onChange(Util.daysAgo(350))}
        variant="contained"
      >
        -1 yr
      </Button>

      <Button
        color="default"
        fullWidth={false}
        onClick={(event) => props.onChange(Util.daysAgo(28))}
        variant="contained"
      >
        -28 days
      </Button>

      <Button
        color="default"
        fullWidth={false}
        onClick={(event) => props.onChange(Util.daysAgo(1))}
        variant="contained"
      >
        -1 day
      </Button>

      <Button
        color="default"
        fullWidth={false}
        onClick={(event) => props.onChange(new Date())}
        variant="contained"
      >
        0 day
      </Button>
    </div>
  );
}
