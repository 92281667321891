import { AwsClient } from "aws4fetch";

const apiPath = "https://api.reflectlog.com/api/v1";
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json;charset=UTF-8",
};

export class Backend {
  private awsClient: AwsClient;

  constructor(awsClient: AwsClient) {
    this.awsClient = awsClient;
  }

  async getEntry(entryId: string): Promise<any | null> {
    console.log("getEntry", entryId);

    const url = `${apiPath}/entries/${entryId}`;
    const options = {
      headers,
      method: "GET",
    };
    const response = await this.awsClient.fetch(url, options);
    if (response && response.ok) {
      return await response.json();
    }
    if (response.status === 404) {
      return null;
    }
    console.error("Error", response.status, response.statusText);
    return null;
  }

  async postEntry(entry: any): Promise<boolean> {
    console.log("postEntry", entry.entryId, entry);

    const url = `${apiPath}/entries/${entry.entryId}`;
    const options = {
      body: JSON.stringify(entry),
      headers,
      method: "POST",
    };
    const response = await this.awsClient.fetch(url, options);
    if (response && response.ok) {
      return true;
    }
    console.error("Error", response.status, response.statusText);
    return false;
  }

  async postEntryRead(entryId: string, readAt: string): Promise<boolean> {
    console.log("postEntryRead", entryId, readAt);

    const url = `${apiPath}/entries/${entryId}/read`;
    const options = {
      body: JSON.stringify({ entryId, readAt }),
      headers,
      method: "POST",
    };
    const response = await this.awsClient.fetch(url, options);
    if (response && response.ok) {
      return true;
    }
    console.error("Error", response.status, response.statusText);
    return false;
  }
}
